import React from 'react';
import Layout from '../components/Layout';
import Scroll from '../components/Scroll';
import Icon from '../components/Icon';
// import pic1 from '../assets/images/pic01.jpg';
// import pic2 from '../assets/images/pic02.jpg';
// import pic3 from '../assets/images/pic03.jpg';
import config from '../../config';
var Recaptcha = require('react-recaptcha');

const IndexPage = () => (
  <Layout>
    <section id="banner">
      <div className="inner">
        <h2>{config.heading}</h2>
        <p>{config.subHeading}</p>

      </div>
    </section>

    <section id="one" className="wrapper style1 special">
      <div className="inner">
        <header className="major">
          <h2>
            Looking for a reliable remodeling contractor?
          </h2>
          <p>Established in 2000, we've built our business with a commitment to complete customer satisfaction.<br />
             With all of our business coming from referrals, we're committed to providing you and your family with<br />
             the service and products you expect, and the finished result you dream of.
          </p>
        </header>
      </div>
    </section>

    {/* <section id="two" className="wrapper alt style2">
      <section className="spotlight">
        <div className="image">
          <img src={pic1} alt="" />
        </div>
        <div className="content">
          <h2>
            Magna primis lobortis
            <br />
            sed ullamcorper
          </h2>
          <p>
            Aliquam ut ex ut augue consectetur interdum. Donec hendrerit
            imperdiet. Mauris eleifend fringilla nullam aenean mi ligula.
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={pic2} alt="" />
        </div>
        <div className="content">
          <h2>
            Tortor dolore feugiat
            <br />
            elementum magna
          </h2>
          <p>
            Aliquam ut ex ut augue consectetur interdum. Donec hendrerit
            imperdiet. Mauris eleifend fringilla nullam aenean mi ligula.
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={pic3} alt="" />
        </div>
        <div className="content">
          <h2>
            Augue eleifend aliquet
            <br />
            sed condimentum
          </h2>
          <p>
            Aliquam ut ex ut augue consectetur interdum. Donec hendrerit
            imperdiet. Mauris eleifend fringilla nullam aenean mi ligula.
          </p>
        </div>
      </section>
    </section> */}

    {/* <section id="three" className="wrapper style3 special">
      <div className="inner">
        <header className="major">
          <h2>Our Services</h2>
          <p>
            Including but not limited to: drywalling , painting, door and window replacement,<br />
            siding, general household repairs, and so much more.
          </p>
        </header>
        <ul className="features">
          <li>
            <Icon title="compass" />
            <h3>Additions</h3>
            <p>
              Have you out-grown your home but don't want to move?
              Building an addition onto your existing home is a great investment.
            </p>
          </li>
          <li>
            <Icon title="hammer" />
            <h3>Decks</h3>
            <p>
              Create outdoor living spaces to relax and entertain guests.
              We can build, repair or replace a deck for your home.
            </p>
          </li>
          <li>
            <Icon title="kitchen" />
            <h3>Kitchens</h3>
            <p>
              As the "heart of a home", the kitchen is one of the most used
              locations in your house! Make it beautiful and functional.
            </p>
          </li>
          <li>
            <Icon title="bath" />
            <h3>Bathrooms</h3>
            <p>
              Is your bathroom outdated? Let us bring your dream bathroom to life.
              Do you have mold problems? Ask about our mold prevention products.
            </p>
          </li>
          <li>
            <Icon title="bed" />
            <h3>Basement Finishing</h3>
            <p>
              Convert unused space into functional living space.
            </p>
          </li>
          <li>
            <Icon title="flooring" />
            <h3>Flooring</h3>
            <p>
              Do your floors need repair, refinishing or replacement? Give us a call.
            </p>
          </li>
        </ul>
      </div>
    </section> */}

    <section id="contact" className="wrapper style4">
      <div className="inner">
        <header className="major">
          <h2>Contact</h2>
          <p>
            <strong>Call (608) 279-9321</strong>
          </p>
          <p>
            Serving Madison, Wisconsin and the greater Dane County area.
          </p>

          <form action="https://getform.io/f/c0bbf045-1ded-42bf-8920-5b62a3504d26" method="POST">
            <label>
              Name
              <input type="text" name="name" id="name" />
            </label>
            <label>
              Email
              <input type="email" name="email" id="email" />
            </label>
            <label>
              Phone
              <input type="text" name="phone" id="phone" />
            </label>
            <label>
              Message
              <textarea name="message" id="message" rows="5" />
            </label>
            <Recaptcha
              className="g-recaptcha"
              sitekey="6LdkJu4UAAAAAMJM-eVoJwdDPGzLvQToWKj5kgXc"
            />
            <button type="submit" className="send">Send</button>
            <input type="reset" value="Clear" />
          </form>
        </header>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
